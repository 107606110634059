.schema-boundary-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: .5rem;
  border-bottom: 0.0625rem solid $gray6;
  &:last-child{
    border: 0;
  }

  &-title {
    font-size: .875rem;
    label{
      color: $dgray3;
      padding-right: .25rem;
      font-weight: 600;
    }
    padding-bottom: .5rem;
  }

  &-content{
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    .dnd-target{
      flex: 1;
    }

    .objects{
      flex: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .object-wrapper{
        // flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: .25rem;
        position: relative;

        .object-remove{
          position: absolute;
          right: -0.55rem;
          top: -0.75rem;
          font-size: 1.25rem;
          color: $halfred;
          // font-weight: 600;
          cursor: pointer;
          &:hover{
            color: $mainred;
          }
        }
      }
    }
    .object-class{
      flex: 1;
      padding: 0.25rem;
      border: 0.0625rem solid $gray6; 
      border-radius: 0.25rem;
      color: $gray8;
      font-size: 0.875rem;
      font-weight: 400;
    }
    

    .object-clear {
      cursor: pointer;
      width: 1.5rem;
      height: 1.5rem;
      z-index: 5;
    }
    @import "./drop-place.scss";
  }
}