.issues {
  flex: 1;
  display: flex;
  flex-flow: nowrap column;
  overflow:hidden;
  align-items: stretch;
  position: relative;
  &-preloader{
    position: absolute;
    left: calc(50% - 1.5rem);
    top: 50%;
    transform: translate(-50%, -50%);
    z-index:10;
  }

  .project-holder{
    text-align: left;
    margin: .5rem;
  }

  &-wrapper {
    flex: 1;
    // width: 100%;
    // height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: stretch;
    justify-content: flex-start;
  }

  &-header {
    display: flex;
    flex-direction: row;
    overflow: hidden;

    &-panels{
      flex: 1;
      overflow: hidden;
      display: flex;
    }
    &-buttons{
      display: flex;
      flex-direction: row;
      margin: 0 .5rem 0 0;
      justify-content: flex-end;

      .header-button {
        // margin: 0 .125rem;
        padding: 0;
        margin: 0;
        width: 2rem;
        height: 2rem;
        // border: 1px solid $gray8;
        position: relative;
        cursor: pointer;

        // display: flex;
        // align-items: center;
        // flex-direction: center;
        svg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          color: $gray7;

          width: 1.5rem;
          height: 1.5rem;
        }

        &.active {
          border-bottom: .0625rem solid $mainblue;
          svg {
            color: $mainblue;
          }
        }
      }
    }
  }

  &-footer{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-end;
    padding: .5rem;
    border-top: .0625rem solid $gray6;
  }

  &-body {
    flex: 1;
    // flex-grow: 3;
    display: flex;
    flex-flow: row nowrap;
    overflow: hidden;
  }
  &-panel {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self:stretch;
    overflow: hidden;

  }

  &-content {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: stretch;
    justify-content: flex-start;
  }

  &-sidebar {
    // min-width: 18rem;
    flex: 1;
    // width:100%;
    box-sizing: border-box;
    text-align: center;
    flex-grow: 1;

    &-resize-container {
      width: 30%;
    }
  }

  &-right-panel {
    flex: 1;
    display: flex;
    flex-flow: nowrap column;
    align-items: stretch;
  }

  &-tabs-panel{
    height: 15rem;
  }
}
