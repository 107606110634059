@import "variables.scss";
.issues-cart{
  flex: 1;
  margin: -1rem;
  display: flex; 
  flex-direction: column;

  // min-width: 40rem;
  min-height: 20rem;
  max-width: 40rem;
  max-height: 30rem;
  overflow: hidden;
  
  &-list{
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    margin: 1rem;
    position: relative;

    @import "./issue-item.scss";
  }
}