.search-issue-item{
  display: flex;
  flex-direction: row;
  padding: .5rem 0;
  border-bottom: 0.0625rem solid $dgray2;
  align-items: center;
  &-icon, &-prefix{
    margin-right: .5rem;
  }
  &-icon{
    font-size: 1.5rem;
  }

  &-prefix{
    font-size: 1rem;
    white-space: nowrap;
  }
  &-title{ 
    flex: 1;
    font-size: .85rem;
  }
}