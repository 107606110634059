$border-color: $mainorange;

.journal-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  margin: 0.5rem 1rem 0.5rem 1.5rem;

  font-size: .875rem;
  color: $mainorange;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;

  &-block{
    // margin: 0 1.5rem;
    // padding: 0 1.5rem;
    padding: 0;
  }

  &-header{
    width: 100%;
    &-body {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-left: 0.0625rem solid $border-color;
      padding: 1rem 0 .5rem 1rem;
      position: relative;

      //arrow-up
      &:before {
        content: "";
        width: .5rem;
        height: .5rem;
        border-top: .0625rem solid $border-color;
        border-right: .0625rem solid $border-color;
        margin-right: 1.5rem;
        position: absolute;
        left: -.325rem;
        top: 0;
        transform: rotate(-45deg);
      }
    }

    .issue-user{
      margin-left: -1.875rem;
      z-index: 3;
      .user-name {
        color: $dorange1;
      }

      .user-undefined {
        color: $mainred;
      }
    }

    .delimiter-line {
      border-top: 0.0625rem solid $border-color;
      margin: 0 .5rem;
      flex:1;
    }

    .date-time {
      font-size: .75rem;
      color: $dorange2;
    }
  }

  &-change {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    &-body {
      position: relative;
      &:before{
        content: "";
        position: absolute;
        // border-radius: 50%;
        border: 0.0625rem solid $border-color;
        background-color: $white;
        width: .4rem;
        height: .4rem;
        transform: rotate(45deg);
        left: -0.3rem;
        top: 1rem;
      }
      flex: 1;
      border-left: 0.0625rem solid $border-color;
      padding: .5rem 0 .5rem 1rem;
      display: block;
      text-align: left;
      white-space: break-spaces;
      // overflow: hidden;
      .removed, .old-value {
        color: $dorange3!important;
        text-decoration: line-through;
      }
      .inline {
        margin-right: .25rem;
        display: inline;
        &:last-child {
          margin: 0;
        }
      }
      .property-type, .property-name, .action, .value  {
        display: inline;
        overflow: hidden;
      }
      .property-name, .value {
        color: $dorange3;
      }
      .action {
        .user-name {
          color: $dorange2;
        }
      }

      // flex-direction: row;
      // align-items: flex-start;
      // justify-content: flex-start;

      .toggle-description {
        text-decoration: underline;
        color: $mainblue;
        cursor: pointer;
      }
    }
  }
  &-description {
    // color: $dgray5;
    // background-color: $grayblue2;
    // border: .0125rem solid $grayblue4;
    color: $dorange3;
    background-color: $orange2;
    border: .0125rem solid $border-color;
    text-align: left;

    padding: 1rem 2.5rem;
    border-radius: .25rem;

    white-space: pre-wrap;
    overflow-x: clip;

    ins{
      background-color: $halfgreen;
      text-decoration: none;
    }

    del{
      background-color: $halfred;
      text-decoration: none;
      // text-decoration: line-through;
    }
  }

  &-notes {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    &-body {
      flex: 1;
      border: 0.0625rem solid $halforange;
      // padding: .5rem 0 .5rem 1rem;
      padding: .5rem;
      text-align: left;
      background-color: $orange1;
      color: $dorange3;
      border-radius: .25rem;
      border-top-left-radius: 0;
      .markdown-code{
        color: $dorange3;
      }

      .markdown-view {
        blockquote{
          border-left-color: $dorange1;
          background-color: $orange3;
        }
      }
    }
  }
}
