@import "variables";

.qa-sidepanel {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: $gray1;
  position: relative;

  .result-render {
    .service {
      background: $gray3;
      margin: 0.0625rem 0;
      display: flex;
      flex-direction: column;
      padding: 0.5rem;
      margin: 0.5rem;
      border-radius: 0.25rem;
      .row {
        display: flex;
        flex-direction: row;
        font-weight: 100;
      }
      .reason {
        margin: 0.125rem;
        margin-top: 0.5rem;
        padding: 0.5rem;
        background: #fff;
        border-radius: 0.25rem;
        font-size: 0.85rem;
        font-weight: 100;
        text-align: left;
      }
      .status {
        border: none;
        padding: 0;
        margin-right: 0.5rem;
        width: 1rem;
        height: 1rem;
        border-radius: 1rem;
        background: #f1ad5c;
        overflow: hidden;
        text-overflow: ellipsis;

        &.alive {
          background: #76c84f;
        }
        &.dead {
          background: #e44f4f;
        }
      }
    }
  }
  .preload-wrapper {
    // margin: 2rem auto;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($color: #000000, $alpha: 0.1);
  }

  .preloader {
    stroke: $dgray2 !important;
  }

  .object-holder {
    background: $gray2;

    .lock-icon {
      svg.lock {
        display: none;
      }
    }

    .target-object {
      display: flex;
      flex: 1 1;
      background: #f3f3f3;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 0;
      border-bottom: 0.0625rem solid #e6e6e6;
      display: flex;
      font-size: 1.25rem;
      font-weight: 100;
      padding: 0;
      line-height: 2rem;
    }
  }

  .qa-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    background: $gray1;

    .buttons-holder {
      padding: 0.5rem;
      display: flex;
      flex-direction: row;
      justify-content: center;

      .uif-components-button {
        flex: 1;
        max-width: 10rem;
      }
    }
  }
}
