$color-mainblue: #01abfb;
$color-orange: #fbaa38;

.undefined-uid, .no-representation-wrapper, .need-select-project-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $color-mainblue;

  &-icon {
    height: 3rem;
    width: 3rem;
  }
  &-text {
    font-size: 2rem;
    font-weight: 100;
    text-align: center;
  }
}

.undefined-uid {
  color: $color-mainblue;
}

.no-representation-wrapper {
  color: $color-orange;
}

.need-select-project-wrapper{
  color: $gray7;
}

@import "./header-panel";
