@import "variables.scss";
.image-editor{
  flex: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden;


  &-toolbar{
    position: absolute;
    bottom: 1rem;
    left:50%;
    transform: translateX(-50%);
    padding: .25rem;
    border-radius: .25rem;
  }

  .dropdown-button-menu {
    left: 0;
    right:auto;
    .menu-item-title {
      white-space: nowrap;
    }
  }

  &-preview{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    & > img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  .cropper {
    align-items: center;
    flex: 1;
    display: flex;
    height: 100%;
    justify-content: center;

    & > img {
      max-height: 100%;
      max-width: 100%;
    }

  }
}