.attached-issues{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  position: relative;

  &-mask{
    display: none;
    position: absolute;
    top:0;
    left:0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.05);
  }
  @import "./issue-group.scss";
}