.issues-cart-list-item{
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 0.0625rem solid $dgray2;
  padding-bottom: .5rem;
  margin-bottom: .5rem;

  &-num {
    min-width: 2rem;
  }

  &-title{
    flex: 1;
    overflow: hidden;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    span {
      flex: 1;
      font-size: .875rem;
    }
    .issue-icon {
      font-size: 1rem;
      margin-right: .5rem;
      svg{
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  &-buttons{
    margin-left: 0.5rem;
  }
}