.issue-tabs {
  flex-direction: column;
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  overflow: hidden;

  
  &-body{
    flex: 1;
    flex-direction: column;
    display: flex;
    align-items: stretch;
    overflow: hidden;

    .react-tabs__tab-panel{
      display: flex!important;
    }
  }

  &-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    overflow-y: hidden;
    overflow-x: auto;
    background-color: $grayblue2;
    border-bottom: .0625rem solid $grayblue4;
  }


  &-tab {
    min-width: 3rem;
    max-width: 6rem;
    height: 1.5rem;
    // padding: 0 1rem 0 .5rem;
    padding: 0 1rem 0 0;
    user-select: none;
    border-radius: 0;
    overflow: hidden;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    display: flex;
    align-items: center;

    font-size: 0.75rem;

    color: $dgrayblue3;
    margin-bottom: -.0625rem;
    border:0;
    border-right: .0625rem solid $grayblue4;
    white-space: nowrap;
    p {
      padding: 0 .5rem;
    }

    &.selected {
      color: $dblue3;
      background-color: $grayblue4;
      border:0;
    }

    &-body {
      overflow: hidden;
    }

    &-list{
      min-width: 5rem;
    }


    &-close{
      width: 1rem;
      height: 1rem;
      padding: 0.125rem 0.125rem;
      position: absolute;
      vertical-align: top;
      right: 0;
      top: 0;
      svg {
        width: 1rem;
        height: 1rem;
        padding: 0;
        vertical-align: top !important;
        margin: 0;
        line-height: 1rem;
        user-select: none;
        position: relative;
        transition: 0.2s all linear;
        color: $dblue3;
      }
    }
  }
}
