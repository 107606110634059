.email-item{
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  position: relative;

  margin: 0;
  padding: .5rem 0;

  border-bottom: 0.0625rem solid $gray7;

  &.disabled{
    background-color: $gray4;
  }

  &-num {
    width: 2rem;
    text-align: left;
    margin: auto .5rem;
  }

  &-icon {
    min-width: 1.5rem;
    text-align: left;
    margin: auto .5rem;
    &.padding-top{
      margin-top: 1.25rem;
    }
    svg{
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &-title {
    flex: 1;
    text-align: left;
    margin: auto .5rem;
    overflow: hidden;
  }

  &-controls {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // justify-content:space-around;
    &-button {
      height: 2.5rem!important;
    }

    .ais-field-container.no-label {
      margin-top: -.5rem;
    }

    &.padding-top{
      margin-top: 1.5rem;
      align-items: flex-start;
    }
  }
}
