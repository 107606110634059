$border-color: $mainorange;

.journal-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  
  margin: 0.5rem 1rem 0.5rem 1.5rem;

  font-size: .875rem;
  color: $dorange1;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;

  &-block{
    // margin: 0 1.5rem;
    // padding: 0 1.5rem;
    padding: 0;
  }

  &.order-desc {
    .journal-item-header {
      &-body {
        padding: 1rem 0 .5rem 1rem;
        //arrow-up
        &:before {
          content: "";
          width: .5rem;
          height: .5rem;
          border-top: .0625rem solid $border-color;
          border-right: .0625rem solid $border-color;
          position: absolute;
          left:-.3rem;
          top: 0;
          transform: rotate(-45deg);
        }
      }
    }
    .journal-item-bottom-direction{
      display: none;
    }
  }

  &-header{
    width: 100%;
    &-body {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-left: 0.0625rem solid $border-color;
      padding: 0 0 .5rem 1rem;
      position: relative;

      //arrow-up
      // &:before {
      //   content: "";
      //   width: .5rem;
      //   height: .5rem;
      //   border-top: .0625rem solid $border-color;
      //   border-right: .0625rem solid $border-color;
      //   margin-right: 1.5rem;
      //   position: absolute;
      //   // left: -.275rem;
      //   left:-.35rem;
      //   top: 0;
      //   transform: rotate(-45deg);
      // }
    }

    .journal-user{
      margin-left: -1.875rem;
      z-index: 3;
    }

    .delimiter-line {
      border-top: 0.0625rem solid $border-color;
      margin: 0 .5rem;
      flex:1;
    }

    .date-time {
      font-size: .75rem;
      color: $dorange2;
    }

  }

  &-info {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    &-body {
      position: relative;
      &:before{
        content: "";
        position: absolute;
        // border-radius: 50%;
        border: 0.0625rem solid $border-color;
        background-color: $white;
        width: .4rem;
        height: .4rem;
        transform: rotate(45deg);
        left: -0.3rem;
        top: 1rem;
      }
      flex: 1;
      border-left: 0.0625rem solid $border-color;
      // padding: .5rem 0 .5rem 1rem;
      padding: .5rem 0 0 1rem;
      display: flex;
      text-align: left;
      // white-space: break-spaces;
      flex-direction: column;

      .action {
        // text-transform: capitalize; 
        flex: 1;
        color: $mainorange;
      }
      .text {
        flex: 1;
        margin-top: -.75rem;
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        color: $mainorange;
        overflow: hidden;
        
        .simple-text {
          &:first-child{
            &:first-letter{
              text-transform: uppercase;
            }
          }
          line-height: 3rem;
        }

        ins{
          background-color: $halfgreen;
          text-decoration: none;
        }

        del{
          background-color: $halfred;
          text-decoration: none;
          // text-decoration: line-through;
        }

        .ais-object{
          min-width: 4rem;
          margin: 0 0.25rem;
          border-color: $dorange2;
          flex: initial;
          &:hover{
            background-color: $orange2
          }
          .label{
            min-height: 1.5rem;
            white-space: break-spaces;
            max-height: fit-content;
            img {
              object-fit: contain;
              max-width: 100%;
              max-height: 100%;
            }

            math-field{
              position: relative;
              display: flex;
              align-items: flex-start;
              justify-content: center;
              outline: none;
            }
          }
        }
      }

      .object {
        display: flex;
        text-align: left;
        flex-direction: row;
        color: $dorange3;
        .class {
          flex: 1;
        }

        .version {
          color: $dorange2;
        }
      }

      .toggle-description {
        text-decoration: underline;
        color: $mainblue;
        cursor: pointer;
      }
    }
  }

  &-payload{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-left: 0.0625rem solid $border-color;
    // border-top-right-radius: .25rem;
    // border-bottom-right-radius: .25rem;
    overflow: hidden;
    width: 100%;
    padding: 0 0 0 1rem;

    &-title {
      text-align: left;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .title-label{
        color: $dorange3;
        padding-right: .25rem;
      }

      .toggle{
        color: $mainblue;
        text-decoration: underline;
      }
    }


    &-body {
      flex: 1;
      margin-right: 1rem;
      padding: .5rem;
      overflow: hidden;
      border: 0.0625rem solid $border-color;
      border-radius: .25rem;
      background-color: $orange2;

      .payload-preview {
        color: $dorange3;
        text-align: left;
        white-space: normal;
        height: 3rem;
        overflow: hidden;
      }

      pre {
        // background-color: $orange1;
        background-color: transparent;
        padding: 0;
        margin: 0;

        &::-webkit-scrollbar {
          height: .5rem;

        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: $orange1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $halforange;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background:  $mainorange;
        }
      }
    }
    &-arrow {
      .arrow-button {
        width: 2rem;
        cursor: pointer;
        svg{
          width: 1.5rem;
          height: 1.5rem
        }
      }
    }
  }

  &-bottom-direction{
    width: 100%;
    height: 1.5rem;
    border-left: 0.0625rem solid $border-color;
    position: relative;

    //arrow-down
    &:after {
      content: "";
      width: .5rem;
      height: .5rem;
      border-bottom: .0625rem solid $border-color;
      border-left: .0625rem solid $border-color;
      position: absolute;
      left: -.3rem;
      top: .9rem;
      transform: rotate(-45deg);
    }

  }
}
