.change-password{
  flex: 1;
  overflow: auto;
  margin: 1rem;
  max-width: 80rem;
  &-body{
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // align-items: flex-start;
    align-items: center;
    justify-content: center;

    &-left, &-right{
      flex: 1;
      display: flex;
      flex-direction: column;
      min-width: 18rem;
      margin: 0.5rem 1rem;
    }

    &-left{
      align-items: center;
    }

    &-right{
      align-items: stretch;
    }
  }

  &-description{
    h1{
      font-size: 1.25rem;
      color: $dgrayblue3;
      margin-top: 0 ;
    }

    span {
      font-size: .875rem;
      color: $dgrayblue1;
    }
  }

  &-footer{
    // padding-top: .5rem;
    display: flex;
    flex-direction: row;
    // align-items: flex-end;
    justify-content: flex-end;;
  }

  .passwords-not-equal{
    text-align: left;
    color: $mainorange;
    font-size: .75rem;
  }
}