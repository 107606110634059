$border-color: $dyellow2;
$bg-color: $yellow3;
.create-issue-wizard{
  position: absolute;
  top: 3rem;
  left:0;
  width: calc(100% - 2.25rem);
  border-radius: .25rem;
  border: 0.0625rem solid $border-color;

  background-color: $bg-color;
  text-align: left;
  margin: 0 .5rem;
  padding: .5rem;
  z-index: 2;
  // box-shadow: 0 .875rem 4rem rgba(0,0,0,0.25), 0 4rem 4rem rgba(0,0,0,0.22);
  // box-shadow: 0 0.125rem 0.25rem -0.15rem rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.7);

  &:before, &:after {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    left: 2.5rem;
    top: -.5rem;
    // border: .5rem solid $gray4;
  }

  &:before {
    border-right: .5rem solid transparent;
    border-bottom: .5rem solid $border-color;
    border-left: .5rem solid transparent;
  }

  &:after {
    border-right: .5rem solid transparent;
    border-bottom: .5rem solid $bg-color;
    border-left: .5rem solid transparent;
    top: -.4rem;
  }

  &-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .uif-components-button{
      color: $white;
      background-color: $dyellow2;
      &:hover{
        background-color: $dyellow3;
      }
    }
  }

  &-body{
    .ais-field{
      border-color: $dyellow1; 
      &-label{
        color:  $dyellow2; 
      }
    }
    &-trackers{
      min-height: 5rem;
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      max-height: 15rem;
      overflow-x: hidden;
      overflow-y: auto;
      margin: .5rem 0 0 0;
      &-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $dyellow2;
      }

      .tracker-item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: .5rem 0;
        border-bottom: .0625rem solid $dyellow1;
        color: $dyellow3;
        font-size: 1rem;
        overflow: hidden;
        line-height: 1.5rem;
        &-num{
          width: 1.5rem;
        }

        &-icon{
          font-size: 1.75rem;
          // svg{
          //   // width: 1.5rem;
          //   // height: 1.5rem;
          // }
        }

        &-title{
          flex: 1;
          padding: 0 .25rem;
          line-height: 1.5rem;
        }

        &:last-child{
          border:0;
        }
      }
    }
  }
}