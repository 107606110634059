.search-issue-form{
  min-width: 40rem;
  min-height: 30rem;
  max-width: 40rem;
  max-height: 30rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  &-header {
    display:  flex;
    flex-direction: row;
    align-items: stretch;

    &-project{
      flex: 1;
    }

    &-text{
      margin-left: .5rem;
      flex: 2;
    }
  }

  &-body{
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;
    position: relative;

    .not-found{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      text-align: center;
    }

    @import "./founded-item.scss";
  }
}