.filters {
  // align-items: stretch!important;
  // background-color: $grayblue4;
  color: $dgrayblue2;
  text-align: left;
  flex: 1;
  overflow: auto;
  
  &-active {
    svg {
      color: $mainblue !important;
    }
  }

  &-preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
  }

  &-wrapper {
    overflow: hidden;
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .filters-users-group {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
  }

  .vertical-divider {
    // border-left: .0625rem solid $gray4;
    margin: .75rem .75rem 0.25rem .75rem;
  }

  .horisontal-divider {
    // border-top: .0625rem solid $gray4;
    margin: .25rem 0;
  }

  &-content {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: .5rem 1rem;

    .label {
      text-align: left;
      font-size: .875rem;
      color: $gray8;
    }

    &-parameters {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      overflow: auto;
      margin: 0 -1rem;
      padding: 0 1rem;

      // overflow-y: auto;
      // overflow-x: hidden;

      &-fields {
        flex: 1;
        // display: flex;
        // flex-direction: column;
        // align-items: stretch;
        // justify-content: flex-start;

        overflow: visible;

        .date-block {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          justify-content: flex-end;
          overflow: hidden;

          .react-datepicker-popper {
            z-index: 15;
          }

          .dots {
            vertical-align: bottom;
            text-align: center;
            width: 1rem;
            height: 1.125rem;
            border-top: 0.0625rem solid $gray7;
          }
        }
      }
    }

    &-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content:space-between;
      &-left{
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }
      &-right{
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }

  &-header {
    background-color: $blue5;

    svg {
      background-color: $mainblue;
      color: $white;
    }
  }

  &-shown svg {
    color: $mainblue !important;
  }
}
