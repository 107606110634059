.attached-issues-item{
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  text-align: left;  
  &-num{

  }
  &-title{
    &-num{
      &-id{
        
      }
    }
    &-subject{

    }
  }
}