.source-view {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  &-empty-tabs{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.25rem;
    font-weight: 400;
    color: $mainblue;
    text-align: center;
  }
  .react-tabs{
    overflow: hidden;
    &__tab {
      &-list {
        min-width: 5rem;
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        overflow-y: hidden;
        overflow-x: auto;
        background-color: $grayblue2;
        border-bottom: .0625rem solid $grayblue4;
      }

      height: 1.5rem;
      padding: 0 .5rem 0 0;
      
      user-select: none;
      border-radius: 0;
      cursor: pointer;

      display: flex;
      align-items: center;

      font-size: 0.75rem;

      color: $dgrayblue3;
      // margin-bottom: -.0625rem;
      border:0;
      border-right: .0625rem solid $grayblue4;
      white-space: nowrap;
      p {
        padding: 0 .5rem;
      }

      &--selected {
        color: $dblue3;
        background-color: $grayblue4;
        border:0;
      }

      .code-tab{
        &-body {
          overflow: hidden;
        }
        &-close{
          width: 1rem;
          height: 1rem;
          padding: 0.125rem 0.125rem;
          position: absolute;
          vertical-align: top;
          right: 0;
          top: 0;
          svg {
            width: 1rem;
            height: 1rem;
            padding: 0;
            vertical-align: top !important;
            margin: 0;
            line-height: 1rem;
            user-select: none;
            position: relative;
            transition: 0.2s all linear;
            color: $dblue3;
          }
        }
      }
    }
  }
}