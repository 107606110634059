@import "variables";

$fontcolor: $dyellow1;
$darkfont: $dyellow3;
$backcolor: $yellow1;
$deepbackcolor:$yellow2;
$bordercolor: $yellow3;
$groupbordercolor: $halfyellow;
$cursorbackground: $yellow3;

.issues-sidepanel{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  
  .object-holder {
    background: $deepbackcolor;

    .lock-icon {
      svg.lock {
        display: none;
      }
    }

    .target-object {
      background: $deepbackcolor;
      border-bottom-color: $bordercolor;
    }
  }

  &-body{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
    background: $backcolor;
    position: relative;
    .preloader-center {
      stroke: $dyellow2 !important;
      left: calc(50% - 1rem);
    }
    .issues-sidepanel-toolbar{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem;
      position: relative;
      // border-bottom: 0.0625rem solid $mainyellow;

      > .uif-components-button {
        background: $dyellow2 !important;
        color: $white !important;
        height: 2rem;
        font-size: 0.875rem;

        &:before {
          content: none;
        }

        &:not(.disabled):not(.outline):hover {
          box-shadow: none;
          color: white !important;
          background-color: $dyellow3 !important;
        }
      }
    }

    .header {
      margin: 1rem;
      text-align: center;

      .text-holder {
        background: transparent;
      }
    }
  }
  
  @import "../../components/attachedIssues/css/";
}

@import "../../components/attachedIssues/css/search-form.scss"