@import "~@ais3p/ui-framework/colors.scss";

.object-holder {
  .target-object {
    display: flex;
    flex: 1;
    background: $gray3;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    border-bottom: 0.0625rem solid $gray4;
    display: flex;
    font-size: 1.25rem;
    font-weight: 100;
    padding: 0;
    line-height: 2rem;

    svg.preloader {
      margin: 0;
    }

    .object-name {
      padding: 0.25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: default;
    }
    .expander.big {
      &.list-bullet-S {
        rect {
          fill: $gray6;
        }
        text {
          text-align: center;
          stroke: none;
          fill: white;
        }
      }
    }
    .merge-icons {
      .lock-icon {
        width: 0.75rem;
        height: 0.75rem;
        right: 0;
        bottom: 0;

        .lock {
          stroke: $gray3;
        }
      }
      .validate-icon {
        width: 0.75rem;
        height: 0.75rem;
        right: 0;
        bottom: 0;
        .validate {
          stroke: $gray3;
        }
      }
    }

    svg {
      width: 1.75rem;
      height: 1.75rem;
      margin-left: 0.25rem;
      flex-shrink: 0;
    }

    .target-info {
      margin-left: auto;
      cursor: pointer;
    }
  }
}
