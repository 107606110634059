@import "~@ais3p/ui-framework/colors.scss";
@import "../../../resources/fonts/index.scss";

.shrink-0 {
  flex-shrink: 0;
}
.plus-holder {
  position: relative;
  display: flex;
  justify-content: flex-start;
  transition: all linear 0.2s;
}

.menu-items-preview {
  height: 0.125rem;
  background: $mainblue;
  border-radius: 0.125rem;
  margin: 0 0.5rem 0 0;
}
.plus-preview {
  height: 0.125rem;
  margin-top: -0.125rem;
}
.picture-editor-image-browse {
  width: 1px;
  height: 1px;
  opacity: 0;
}
.plus-menu-container {
  position: absolute;
  left: 0rem;
  top: 2.5rem;
  z-index: 4;
  max-height: 15rem;
  overflow: visible;

  .menu-items-holder.expanded {
    margin: 0;

    .add-item {
      font-weight: 100;
      border: 0.25rem solid #333;
      margin: 0;
      min-width: 1.75rem;
      display: flex;
      padding: 0.25rem;
      align-items: center;
      justify-content: center;
      font-size: 1.5rem;
      flex-shrink: 0;
      border-radius: 0;
      color: #fff;
      background: #333333;
      cursor: pointer;
      transition: all linear 0.1s;
      position: relative;
      margin-bottom: -0.125rem;

      .dimentions-holder {
        padding: 0.25rem;
        background: #333;
        border-radius: 0.25rem;
        border-top-left-radius: 0;
        position: absolute;
        top: 100%;
        left: 0;
        display: none;

        .dimention {
          background-color: #222;
          width: 1rem;
          height: 1rem;
          margin: 0.0625rem;
          border-radius: 0.125rem;
          cursor: pointer;

          &:hover,
          &.hovered {
            background-color: $mainblue;
          }
        }
      }

      &:hover {
        .dimentions-holder {
          display: grid;
        }
      }

      &:hover {
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 0.25rem;
          background: #222222;
        }
        color: $mainblue;

        div.number {
          color: $mainblue;
          z-index: 2;
        }
      }
      svg {
        z-index: 2;
        width: 1.75rem;
        height: 1.75rem;
      }
    }

    .items-holder {
      .add-item {
        &:first-child {
          border-top-left-radius: 0rem;
          border-top-right-radius: 0rem;

          &:last-child {
            border-bottom-left-radius: 0rem;
            border-bottom-right-radius: 0rem;
          }
        }
      }

      &:first-child {
        .add-item {
          &:first-child {
            border-top-left-radius: 0rem;
          }
          &:last-child {
            border-bottom-left-radius: 0.25rem;
          }
        }
      }

      &:last-child {
        .add-item {
          &:first-child {
            border-top-right-radius: 0rem;
          }
          &:last-child {
            border-bottom-right-radius: 0.25rem;
          }
        }
      }
    }
  }
}

.menu-items-holder {
  height: 0;
  transition: all linear 0.1s;
  padding: 0;
  margin: 0;
  transform: none;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .add-item {
    font-weight: 100;
    border: 0.125rem solid #aaa;
    margin: 0;
    margin-left: -0.125rem;
    display: flex;
    padding: 0.125rem;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    flex-shrink: 0;
    color: #fff;
    background: #c6c6c6;
    cursor: pointer;
    transition: all linear 0.1s;
    position: relative;

    &:first-child {
      margin-left: 0rem;
    }

    &:hover {
      background: #ddd;
    }

    .number {
      margin-right: 0.25rem;
      font-size: 1rem;
    }
  }

  > .add-item {
    &:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
    &:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
  }

  .items-holder {
    .add-item {
      margin-bottom: -0.125rem;
      margin-left: 0;

      &:last-child {
        margin-bottom: -0;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
    }

    &:first-child {
      .add-item {
        &:first-child {
          border-top-left-radius: 0.25rem;
        }
      }
    }

    &:last-child {
      .add-item {
        &:first-child {
          border-top-right-radius: 0.25rem;
        }
      }
    }
  }

  &.expanded {
    transform: none;
    min-height: 2rem;
    height: auto;
    margin: 0.25rem;
  }
}

.dialog-content {
  .dnd-content-confirm-icon {
    width: 1.75rem;
    height: 1.75rem;
    margin-right: 0.125rem;
    flex-shrink: 0;
    border-radius: 0.25rem;
    align-items: center;
    text-align: center;
    justify-content: center;
    text-indent: 0;
    font-size: 1.5rem;
    font-weight: bold;
  }

  .levels {
    display: flex;
    flex-direction: column;

    .button-holder {
      display: flex;
      flex: 1;
      flex-direction: column;
    }

    .uif-components-button {
      justify-content: flex-start;
    }
  }
}

.picture-editor {
  margin: -1rem;
  min-width: 40rem;
  min-height: 30rem;
  align-items: stretch;
  justify-content: center;
  display: flex;
  flex-direction: column;

  .picture-editor-image {
    flex: 1;
    display: flex;
    align-items: stretch;
    justify-content: center;
    position: relative;
    .dnd-target {
      flex: 1;
    }

    &-browse-body {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 0.75rem;
      a {
        color: $mainblue;
        text-decoration: none;
      }
    }

    &-empty-icon {
      width: 10rem;
      height: 10rem;
      z-index: 1;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.added-icon {
  fill: $maingreen;
}
.removed-icon {
  fill: $mainred;
}
.material {
  background: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  counter-reset: tableCounter formulaCounter imageCounter;

  .virt-list-item {
    &.preview {
      &.can-drop {
        background-color: $blue3;
      }
      &.can-not-drop {
        background-color: $red3;
      }
      .item-holder {
        &.focus {
          background-color: transparent;
        }
      }
      .rubric {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
  .dnd-target {
    &.text-flat-list-item-drop-target {
      background-color: transparent;
      padding-bottom: 0.125rem;
      &.can-drop {
        .preview {
          background-color: $blue2;
        }
      }
      &.can-not-drop {
        .preview {
          background-color: $red3;
        }
      }
    }
  }

  .removed {
    background: $red1;
    &.header-cell {
      &.table-cell {
        background: $red2;
      }
    }
  }
  .added {
    background: $green1;
    &.header-cell {
      &.table-cell {
        background: $green2;
      }
    }
  }

  &.with-diff {
    .plus-holder,
    .corner-holder {
      display: none !important;
    }
    .expand-menu {
      opacity: 0 !important;
      cursor: default !important;
    }
    .tool-bar-button {
      svg[name="fork-M"] {
        color: $mainblue;
      }
    }
  }

  .version-List {
    position: absolute;
    right: 0;
    top: 2.5rem;
    z-index: 4;
    background: #333333;
    max-height: 20rem;
    white-space: nowrap;
    overflow: auto;
    border-radius: 0 0 0.25rem 0.25rem;

    .no-versions {
      font-size: large;
      color: white;
      padding: 1rem;
    }

    .version-button {
      padding: 0.5rem 1rem;
      color: white;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 0.0625rem solid $dgray3;

      &.selected {
        color: $mainblue;
      }

      &:hover {
        background-color: $dgray2;
      }

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  .indicator-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  .editor-container {
    flex: 1;
    display: flex;
    overflow: hidden;
    flex-direction: column;

    .items-list-holder {
      display: flex;
      flex: 1;
      overflow: hidden;
      position: relative;
      flex-direction: row;
      > .list-item-body {
        flex: 1;
      }
      .no-content {
        font-size: 2rem;
        font-weight: 100;
        color: #ddd;
        flex-direction: column;
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;

        .expander {
          width: 3rem;
          height: 3rem;
        }
      }

      .Pane.vertical {
        display: flex;
        overflow: hidden;
      }

      .rubric-tree-holder {
        overflow: auto;
        width: 100%;
        background: $gray2;

        .tree-rubric {
          font-size: 0.875rem;
          font-weight: 100;
          color: #000;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          cursor: pointer;
          padding: 0.05rem 0.15rem;
          border-bottom: 0.0625rem solid $gray4;

          .rubric-tree-spacer {
            flex-shrink: 0;
          }

          .vertical-wrapper {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            align-items: flex-start;
          }
          &.focused {
            background-color: $blue1;
            .icon-number-holder {
              // background: white;
              color: $mainblue;
              svg {
                fill: $mainblue;
              }
            }
          }

          .title-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          .icon-number-holder {
            flex-direction: row;
            display: flex;
            color: $dgray6;
            padding-left: 0;
            margin-right: 0.25rem;
            margin-left: 0.25rem;
            overflow: hidden;

            .icon {
              height: 1.25rem;
              width: 1.25rem;
              margin-top: 0.25rem;
            }

            .indicator-container {
              .indicator {
                margin-top: -0.0625rem;
                border-radius: 0;
              }
            }
            svg {
              margin-right: 0;
              fill: #404040;
              // fill: #000;
            }

            .number {
              font-weight: 600;
              color: $gray8;
              padding: 0 0.25rem 0 0.125rem;
              line-height: 1.75rem;
            }
          }

          &:hover {
            background-color: #f4f4f4;
          }

          .title {
            font-size: 0.875rem;
            text-align: left;
          }
        }
      }

      .items-list {
        margin: 0 auto;
        max-width: 100%;
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 0rem;

        .flat-list {
          padding-left: 1rem;
          // scroll-behavior: smooth;
        }

        math-field {
          //display: block;
          position: relative;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          outline: none;
        }

        .caption {
          .prefix {
            padding-right: 0.25rem;
          }

          .number {
            padding-right: 0.25rem;
          }
        }
        .plus-container {
          display: flex;
          cursor: pointer;
          .plus-start-text {
            color: #999;
            margin-top: 0.25rem;
          }
        }
        .expand-menu {
          position: -webkit-sticky;
          position: sticky;
          top: 0rem;
          transition: all linear 0.1s;
          font-size: 1.5rem;
          color: #999;
          cursor: pointer;
          z-index: 3;

          &.expanded {
            transform: rotate(45deg);
          }
        }

        &.plus-holder {
          transition: all linear 0.2s;
          transition-delay: 0.3s;
          align-items: flex-start;
          padding-bottom: 0.5rem;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
        }
        > .plus-holder {
          left: 1rem;
        }
        .plus-holder {
          position: relative;
          display: flex;
          justify-content: flex-start;
          transition: all linear 0.2s;
          z-index: 10;

          .parent {
            background-color: red;
          }
        }

        .menu-items-holder {
          height: 0;
          transition: all linear 0.1s;
          padding: 0;
          margin: 0;
          transform: none;
          display: flex;
          flex-direction: row;
          align-items: flex-start;

          .add-item {
            font-weight: 100;
            border: 0.125rem solid #aaa;
            margin: 0;
            margin-left: -0.125rem;
            margin-bottom: -0.125rem;
            display: flex;
            padding: 0.125rem;
            align-items: center;
            justify-content: center;
            font-size: 1.5rem;
            flex-shrink: 0;
            color: #fff;
            background: #c6c6c6;
            cursor: pointer;
            transition: all linear 0.1s;
            position: relative;

            &:first-child {
              margin-left: 0;
            }

            .number {
              margin-right: 0.25rem;
              font-size: 1rem;
            }

            .dimentions-holder {
              padding: 0.125rem;
              background: #aaaaaa;
              border-radius: 0.25rem;
              border-top-left-radius: 0;
              position: absolute;
              top: 100%;
              left: 0;
              display: none;

              .dimention {
                background-color: #c6c6c6;
                width: 1rem;
                height: 1rem;
                margin: 0.0625rem;
                cursor: pointer;

                &:hover,
                &.hovered {
                  background-color: #ddd;
                }
              }
            }

            &:hover {
              background: #ddd;
              .dimentions-holder {
                display: grid;
              }
            }
          }

          > .add-item {
            &:first-child {
              border-top-left-radius: 0.25rem;
              border-bottom-left-radius: 0.25rem;
            }
            &:last-child {
              border-top-right-radius: 0.25rem;
              border-bottom-right-radius: 0.25rem;
            }
          }

          .items-holder {
            margin-left: -0.125rem;
            .add-item {
              margin-bottom: -0.125rem;
              margin-left: 0;

              &:last-child {
                margin-bottom: -0;
                border-bottom-left-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
              }
            }

            &:first-child {
              .add-item {
                &:first-child {
                  border-top-left-radius: 0.25rem;
                }
              }
            }

            &:last-child {
              .add-item {
                &:first-child {
                  border-top-right-radius: 0.25rem;
                }
              }
            }
          }

          &.expanded {
            transform: none;
            min-height: 2rem;
            height: auto;
            margin: 0.125rem;
          }
        }

        .ReactVirtualized__Grid__innerScrollContainer {
          margin: 0;
          padding-bottom: 50vh;
        }

        .virt-list-item {
          max-width: 80rem;
          min-width: 10rem;
          z-index: 1;
          flex: 1;

          .virt-list-item-content {
            padding-right: 0.5rem;
            padding-bottom: 0 !important;
            position: relative;

            .virt-list-item-content {
              padding-right: 0;
            }

            .expand-menu {
              position: -webkit-sticky;
              position: sticky;
              left: -1.25rem;
              top: 0rem;
              transition: all linear 0.1s;
              font-size: 1.5rem;
              color: #999;
              cursor: pointer;
              opacity: 0;
              z-index: 3;

              &.expanded {
                transform: rotate(45deg);
              }
            }

            &.plus-holder {
              transition: all linear 0.2s;
              transition-delay: 0.3s;
              align-items: flex-start;
              padding-bottom: 0.5rem;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
            }

            .element {
              &:hover {
                > .item-holder {
                  > .expand-menu {
                    opacity: 1;
                  }
                }
                > .expand-menu {
                  opacity: 1;
                }
              }
            }

            .table {
              .table-cell {
                &.top-row {
                  margin-top: 0;
                }
                .expand-menu {
                  position: sticky;
                  top: 0rem;
                  opacity: 1;
                  transition: alllinear 0.1s;
                  font-size: 1.5rem;
                  color: #999;
                  cursor: pointer;
                  z-index: 3;
                }
                .plus-container {
                  position: absolute;
                  z-index: 2;
                }

                .plus-holder {
                  margin-left: 1rem;
                }
                .item-preview-holder {
                  position: absolute;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  top: 0;
                  overflow: hidden;
                  z-index: 1;
                }
                > .dnd-target {
                  background: transparent;
                  z-index: 1;
                  &.text-flat-list-item-drop-target {
                    min-height: 2rem;
                  }
                }
                > .drop-target-layer {
                  min-height: 2.5rem;
                  position: relative;
                  &:hover {
                    > .item-holder {
                      > .expand-menu {
                        opacity: 1;
                      }
                    }
                    > .expand-menu {
                      opacity: 1;
                    }
                  }
                  > .expand-menu {
                    left: 0rem;
                    top: 1.5rem;
                  }
                }
              }
            }
          }

          .caption {
            .plus-holder,
            .expand-menu,
            .indicator-container {
              display: none !important;
            }
          }
        }
      }
    }
  }

  .code {
    padding-bottom: 1rem;

    .code-holder {
      flex: 1;
      display: flex;
      flex-direction: column;
      border-radius: 0.25rem;
      overflow: hidden;

      .lang-select {
        color: #fff;
        padding: 0.25rem;
        background-color: #4d4d4d;
        line-height: initial;
        font-size: 0.875rem;
        font-weight: 100;

        .caption {
          .placeholder {
            min-height: initial;
            text-align: left;
          }
        }

        .Code-select-language {
          .ais-select__indicator-separator {
            display: none;
          }

          .ais-select__single-value {
            color: #ccc;
            text-align: right;
            line-height: 1.5rem;
            height: 1.5rem;
            font-weight: 100;
          }

          .ais-select__clear-indicator {
            display: none;
          }

          .ais-select__control {
            background: transparent;
            border: none;
          }

          .ais-select__input-container {
            text-align: right;
            width: auto;
            display: inline-block;
            height: 1.5rem;
          }
        }
      }

      .code-viewer {
        flex: 1;
        background-color: #f0f0f0;
        counter-reset: lines;

        .code-line {
          white-space: nowrap;
          white-space: nowrap;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;

          .number-holder {
            counter-increment: lines;

            &::after {
              content: counter(lines);
            }

            display: flex;
            align-items: center;
            justify-content: center;
            width: 3rem;
            color: #fafafa;
            background-color: #ccc;
            flex-shrink: 0;
            margin-right: 0.25rem;
          }

          &.added {
            .number-holder {
              background-color: $green3;
            }
          }
          &.removed {
            .number-holder {
              background-color: $red3;
            }
          }

          .item-holder {
            pre {
              min-height: 1.5rem;

              .code-line-body {
                display: block;
                min-height: 1.5rem;
                white-space: nowrap;

                .placeholder {
                  color: #aaa;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }

  .item-holder {
    display: flex;
    flex-direction: row;
    position: relative;

    &.focus {
      background: $blue1;
      border-radius: 0.25rem;
    }

    pre {
      margin: 0;
      padding-top: 0;
      flex: 1;
      padding-bottom: 0;
    }
  }

  .indent {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 400;
    text-align: left;
    display: flex;
    flex-direction: column;
    padding-bottom: 0.125rem;

    .indent-body {
      font-family: 'Times New Roman';
      //margin-top: 0.125rem;
      min-height: 1.25rem;
      margin-bottom: 0.5rem;
      flex: 1;
      align-items: center;
      display: block;

      .placeholder {
        content: "Нет содержимого";
        color: rgba(0, 0, 0, 0.3);
        cursor: pointer;
        line-height: 1.75rem;
      }
      .inline-formula {
        display: inline-block;
        min-width: 1rem;
      }

      .inline-picture {
        display: inline-block;

        img {
          max-height: 2rem;
          width: auto;
          display: block;
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  .formula {
    position: relative;

    .caption {
      margin: 0rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 0.85rem;
      color: #222;

      .indent {
        font-size: 0.85rem;
        font-weight: 400;
        text-align: center;
        text-align: left;
        text-indent: 0rem;
        justify-content: center;
        margin: 0;

        .icon {
          display: none;
        }
      }
    }
  }
  .viewer-content-image {
    background-color: white;
    background-image: linear-gradient(
        45deg,
        rgba(0, 0, 0, 0.05) 25%,
        transparent 25%
      ),
      linear-gradient(-45deg, rgba(0, 0, 0, 0.05) 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, rgba(0, 0, 0, 0.05) 75%),
      linear-gradient(-45deg, transparent 75%, rgba(0, 0, 0, 0.05) 75%);
    background-size: 1rem 1rem;
    background-position: 0 0, 0 0.5rem, 0.5rem -0.5rem, -0.5rem 0;
    img {
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .image {
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;

    .image-body {
      justify-content: center;
      flex: 1;
      flex-direction: column;
      max-width: 100%;
      overflow: hidden;

      .caption {
        margin: 0rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 0.85rem;
        color: #222;

        .chunk {
          flex: initial;
        }

        .indent {
          font-size: 0.85rem;
          font-weight: 400;
          text-align: center;
          text-align: left;
          text-indent: 0rem;
          justify-content: center;
          margin: 0;

          .icon {
            display: none;
          }
        }
      }

      .image-content {
        flex: 1;
        display: flex;
        // background-color: rgba(0, 0, 0, 0.05);
        background-image: linear-gradient(
            45deg,
            rgba(0, 0, 0, 0.04) 25%,
            transparent 25%
          ),
          linear-gradient(-45deg, rgba(0, 0, 0, 0.04) 25%, transparent 25%),
          linear-gradient(45deg, transparent 75%, rgba(0, 0, 0, 0.04) 75%),
          linear-gradient(-45deg, transparent 75%, rgba(0, 0, 0, 0.04) 75%);
        background-size: 1rem 1rem;
        background-position: 0 0, 0 0.5rem, 0.5rem -0.5rem, -0.5rem 0;
        padding: 0.0625rem;
        border-radius: 0.0625rem;
        overflow: auto;

        svg.preloader {
          margin: 0;
        }

        .inline-picture {
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: center;
          max-width: 100%;

          .picture-image-empty-icon {
            width: 4rem;
            height: 4rem;
            color: #777;
          }
        }

        img.image-data {
          max-width: 100%;
          min-width: 2rem;
          margin: 0 auto;
          object-fit: scale-down;
        }
      }
    }
  }

  .table {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;

    .table-holder {
      overflow: hidden;
      display: flex;
      flex: 1;
      flex-direction: column;
      flex-shrink: 0;

      .table-body-holder {
        .table-body {
          overflow: auto;
          &::-webkit-scrollbar {
            position: -webkit-sticky;
          }
        }
      }
      > .caption {
        min-height: 2rem;
        margin: 0rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-size: 1rem;
        color: #222;

        .chunk {
          text-align: left;
        }

        .indent {
          font-size: 1rem;
          font-weight: normal;
        }
      }
      .table-body {
        display: grid;
        flex: 1;
        // overflow-x: auto;
        // overflow-y: hidden;
        border-left: 0.0625rem solid #222;

        .table-cell {
          margin-top: -0.0625rem;
          margin-left: -0.0625rem;
          border: 0.0625rem solid #222;
          min-height: 4rem;
          min-width: 15rem;
          position: relative;
          padding: 1rem 0.25rem 0.25rem 1.5rem;
          overflow: auto;

          .table-cell-dnd-target {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          }
          > .expand-menu {
            left: 1.5rem !important;
          }

          &:hover {
            > .expand-menu {
              display: block;
              opacity: 1;
            }
          }
          &.header-cell {
            background: rgba(0, 0, 0, 0.05);

            &.removed {
              background: $red2;
            }
            &.added {
              background: $green2;
            }

            .icon {
              fill: $dgray1;
            }
          }

          &.hovered {
            background-color: $blue3;

            &.invalid {
              background-color: $red1;
            }
            &.header-cell {
              background: $blue3;
              &.invalid {
                background-color: $red3;
              }
            }
          }
          .drop-target-layer {
            padding: 0.25rem;
            padding-top: 1rem;
            padding-left: 1rem;
            height: 100%;
            box-sizing: border-box;
          }
          .corner-holder {
            display: none;
            position: absolute;
            left: 0;
            top: 0;
            overflow: hidden;
            width: 1.75rem;
            height: 1.75rem;
            cursor: grab;
            z-index: 1;
            .dnd-source {
              width: 100%;
              height: 100%;
            }

            .react-contextmenu-wrapper,
            .drag-source-layer {
              width: 1.75rem;
              height: 1.75rem;
              background-color: transparent;
            }
            &:before {
              content: "";
              width: 2.5rem;
              position: absolute;
              left: -1.5rem;
              top: -1.7rem;
              height: 3rem;
              background: #25aeee;
              transform: rotate(-45deg);
            }

            svg.icon {
              width: 0.875rem;
              height: 0.875rem;
              color: #fff;
              fill: #fff;
              cursor: inherit;
              margin: 0;
              position: relative;
            }
          }
          &:hover {
            .corner-holder {
              display: block;
            }
          }
          .plus-holder {
            margin-left: 2rem;
          }
          &.header-cell {
            .indent {
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  .rubric {
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column;
    padding-bottom: 0.5rem;

    .rubric-body {
      margin-top: 0rem;
      font-size: 1.5rem;
      font-weight: 800;
      text-align: left;
      text-indent: 0rem;
      flex: 1 1;
      flex-direction: row;
      display: flex;
    }

    .indent {
      .indent-body {
        color: #000;
        font-size: 1.5rem;
        font-style: normal;
      }
    }
  }

  .index-number {
    color: $gray8;
    margin-right: 0.75rem;
    line-height: 1.75rem;
    margin-bottom: 0.5rem;
  }

  .icon {
    width: 1.75rem;
    height: 1.75rem;
    margin-right: 0.5rem;
    color: #c6c6c6;
    flex-shrink: 0;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    text-indent: 0;
    font-size: 1.5rem;
    font-weight: bold;

    &.list-bullet-S {
      fill: transparent;
      stroke: #c6c6c6;
    }

    &.focus {
      color: #01abfb;
    }
  }
  .origin-create {
    border-radius: 0.25rem;
    background-color: $green1;
    &.inline-picture {
      padding: 0.125rem;
    }
  }
  .origin-update {
    border-radius: 0.25rem;
    background-color: $blue1;
    &.inline-picture {
      padding: 0.125rem;
    }
  }

  .caption {
    flex: 1;
    display: flex;

    .chunk {
      flex: 1;
    }

    .placeholder {
      content: "Header";
      min-height: 1.5rem;
      flex: 1;
      color: #ddd;
      cursor: pointer;
    }

    .indent {
      flex: 1;
      font-size: 1.125rem;
      font-weight: 800;
      text-align: left;
      text-indent: 0rem;
      color: #777;
      padding: 0;
      font-style: italic;

      .icon {
        display: none;
      }

      .indent-body {
        // margin-top: 0.125rem;
        padding-top: 0rem;
      }
    }
  }

  .formula-editor-image {
    display: flex;
    flex-direction: row;

    .inline-formula {
      flex: 1;
    }
  }

  .formula-body {
    margin-bottom: 0.5rem;
    flex: 1;

    .caption {
      .chunk {
        flex: none;
      }
    }

    .formula-content {
      background-color: #f4f4f4;
      padding: 0.25rem 0.5rem;
      border-radius: 0.125rem;
    }

    math-field {
      .ML__fieldcontainer {
        min-height: 2rem !important;
      }
    }

    .caption {
      .chunk {
        min-width: 1rem;
      }
    }
  }

  .icon {
    &.EnumerationItem {
      // border: 0.0625rem solid $gray6;
      margin-right: 0.125rem;
      // background-color: white;
      z-index: 2;
      rect {
        fill: $gray6;
      }
      text {
        text-align: center;
        stroke: none;
        fill: white;
      }
      &.focus {
        border-color: $mainblue;
        &.list-bullet-S {
          stroke: $mainblue;
          rect {
            fill: $mainblue;
          }
        }
      }
    }
  }

  .list-bullet-render {
    width: 0.3125rem;
    height: 0.3125rem;
    border-radius: 1rem;
    border: 0.0625rem solid #fff;
    margin: 0.525rem;
  }

  .table-cell-skeleton {
    position: relative;
    box-shadow: inset 0 0 0.0625rem rgba(0, 0, 0, 0.3);
    background-image: linear-gradient(
        45deg,
        rgba(0, 0, 0, 0.04) 25%,
        transparent 25%
      ),
      linear-gradient(-45deg, rgba(0, 0, 0, 0.04) 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, rgba(0, 0, 0, 0.04) 75%),
      linear-gradient(-45deg, transparent 75%, rgba(0, 0, 0, 0.04) 75%);
    background-size: 1rem 1rem;
    background-position: 0 0, 0 0.5rem, 0.5rem -0.5rem, -0.5rem 0;
  }

  .dnd-source {
    z-index: 1;
    height: 1.75rem;
    &.dragging ~ .rubric-body {
      opacity: 0.2;
    }
    &.dragging ~ .indent-body {
      opacity: 0.2;
    }
    &.dragging ~ .list-item-body {
      opacity: 0.2;
    }
    &.dragging ~ .table-holder {
      opacity: 0.2;
    }
    &.dragging ~ .image-body {
      opacity: 0.2;
    }
    &.dragging ~ .formula-body {
      opacity: 0.2;
    }
  }

  .list {
    display: flex;
    min-height: 2rem;
    position: relative;
    flex-direction: row;
    border-radius: 0.25rem;
    .list-body {
      display: flex;
      flex-direction: column;
    }
  }
  &.focused {
    background: $blue1;
    border-radius: 0.25rem;

    .icon.EnumerationItem {
      border-color: $mainblue;
      color: $mainblue;
    }
  }

  .list-holder {
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: hidden;
    background: transparent;
    padding-left: 2rem;
    > .dnd-source {
      position: absolute;
      left: 0.25rem;
      top: 0;
    }

    > .list-item-body {
      flex: 1;
    }

    > .indicator-container {
      margin-left: -2rem;
      margin-right: 2rem;
    }
  }

  .plus-holder {
    z-index: 4;
  }

  .list-item-content {
    display: flex;
    flex-direction: row;
  }
  .list-item {
    display: flex;
    position: relative;
    flex-direction: column;
    > .indicator-container {
      position: absolute;
      top: 0.625rem;
      left: 0.625rem;
      bottom: -0.5rem;
      z-index: 1;
    }

    &:last-child {
      > .indicator-container {
        bottom: 0.525rem;
      }
    }

    .list-item-body {
      display: flex;
      flex-direction: column;
      flex: 1;
      border-radius: 0.25rem;

      &.focused {
        background-color: $blue1;
      }
    }

    > .react-contextmenu-wrapper {
      z-index: 2;
      height: 2rem;
      margin-left: -0.75rem;

      > .icon {
        width: 0.3125rem;
        height: 0.3125rem;
        border-radius: 1rem;
        border: 0.0625rem solid #999;
        background-color: #999;
        margin: 0.65rem 0.6875rem;
        margin-right: 0.5rem;
      }
    }

    &.focused {
      > .react-contextmenu-wrapper {
        > .icon {
          border-color: $mainblue;
          background-color: $mainblue;
        }
      }
    }
  }

  .list-body {
    position: relative;
    z-index: 2;
    flex: 1;
    flex-direction: column;
    display: flex;
    margin-left: 0rem;

    .axis {
      position: absolute;
      z-index: 1;
      width: 0.0625rem;
      bottom: 0.375rem;
      top: 1rem;
      background-color: #c6c6c6;
      border-left: 0.25rem solid white;
      border-right: 0.25rem solid white;
      left: 3.0625rem;
    }

    &.focused {
      .axis {
        background-color: $mainblue;
        border-left: 0.25rem solid $blue1;
        border-right: 0.25rem solid $blue1;
      }

      .list-item {
        > .react-contextmenu-wrapper {
          > .icon {
            border-color: $mainblue;
          }
        }
      }

      &:after {
        background-color: $mainblue;
      }
    }

    // &:after {
    //   content: "";
    //   width: 0.3125rem;
    //   height: 0.0625rem;
    //   position: absolute;
    //   left: 3.175rem;
    //   z-index: 2;
    //   bottom: 0.375rem;
    //   background-color: #c6c6c6;
    // }
  }

  span.current {
    height: 1.25rem; // FIX прыжки текста в FF при пустом абзаце T6366
  }
  .chunk,
  .code-line-body {
    outline: none;
    // flex: 1;
    display: inline;
    min-height: 1rem;
    min-width: 0.125rem;
    white-space: pre-wrap;
    //flex-shrink: 0;
    line-height: 1.75rem;

    &.locked {
      color: $gray7;
      cursor: default;
    }
    * {
      white-space: pre-wrap;
    }
  }

  &.version {
    .expand-menu {
      display: none;
    }
  }
}

.confirm-delete-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  .text-content-confirm {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin: 0 0.125rem;
    font-weight: 100;
    &-icon {
      flex-shrink: 0;
      font-size: 1.5rem;
    }
    .lock {
      stroke: none;
    }
  }
}

.show-picture-in-modal {
  width: 80%;
  height: 90%;
  .body {
    padding: 0;
    flex: 1;
    overflow: hidden;
    .viewer {
      overflow: hidden;
    }
  }
}

// moz specific rules
@supports (-moz-appearance:none) {
  .material {
    .rubric-body{
      .index-number {
        line-height: 2rem;
      }
    }
  }
}