.schema-trace-item-drop {
  flex: 1;
  border: 0.125rem dashed $gray4;
  // padding: .5rem;
  color: $gray7;
  .dnd-target{
    padding: .25rem;
    span{
      margin: .25rem;
    }
  }
}