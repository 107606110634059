.user-notifications{
  flex: 1 1;
  overflow: auto;
  margin: 0;
  display: flex;
  flex-direction: column;
  &-enable {
    padding: 1rem;
  }
  &-emails{
    padding: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    padding: 0 1rem 1rem 1rem;

    &.disabled{
      background-color: $gray3;
      // padding: -.5rem;
    }

    &-title{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      &-header{
        color: $dgrayblue1;
      }

      &-buttons{
        cursor: pointer;
        color: $mainblue;
        font-size: 1rem;
        display: flex;
        align-items: center;
        svg{
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }

    &-list{
      flex: 1;
      overflow: auto;
      position: relative;
      list-style: none;
      &-empty{
        font-size: 1rem;
        text-align: center;
        color: $dgrayblue1;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      @import "./email-item.scss";
    }
  }
}