.issue-group{
  .item-header {
    display: flex;
    flex-direction: row;
    height: 2rem;
    align-items: center;
    padding: 0 0.25rem;
    color: $fontcolor;

    .item-name {
      margin-left: 0.5rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 0.875rem;
      font-weight: 600;
    }

    .spacer {
      flex: 1;
      height: 0.0625rem;
      background: $groupbordercolor;
    }

    svg {
      color: $dyellow2;
      cursor: pointer;
      flex-shrink: 0;
      margin: 0.25rem;
      &.add-issue-button{
        font-size: 2rem;
      }
      &.lock {
        flex-shrink: initial;
      }
    }
  }

  .attached-issues-list {
    display: flex;
    flex-direction: column;
    .add-item-line{
      padding: 0.25rem 0.75rem;
    }
    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0.25rem 0.75rem;
      overflow: hidden;
      // margin-bottom: 0.25rem;

      @import "./attached-issue.scss";

      .ais-object{
        overflow: hidden;
      }
      .linked-object {
        &:hover {
          cursor: pointer;
          color: $white;
          background-color: $fontcolor;
          border-color: $darkfont;

          .object .icon {
            color: $white;
          }
        }
      }

      svg.delete {
        display: none;
      }

      &:hover {
        background-color: $cursorbackground;

        svg.delete {
          display: block;
          cursor: pointer;
          &:hover {
            color: $mainred;
          }
        }
      }

      svg {
        width: 1.5rem;
        height: 1.5rem;
        flex-shrink: 0;
        margin: 0 0.25rem;

        &.lock {
          flex-shrink: initial;
        }
      }

      > svg {
        color: $fontcolor;
        margin: 0;
      }
    }
  }  

  
}